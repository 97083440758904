import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  SectionedForm,
  BoxLayout,
  message as alert,
  Icon,
} from "@wellth/web-ui";
import { Alert, Modal } from "antd";
import { CheckInCategory, IdentityTag } from "types/globalTypes";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { SectionContainerProps } from "@wellth/web-ui/src/components/SectionedForm/components/Section";
import {
  checkIfAltProgram,
  getValidatedFormData,
  getValidationAlertList,
  ValidationAlertContent,
} from "components/EnrollmentForm/EnrollmentPolicy";
import { SubmitButton } from "./components/SubmitButton";
import {
  MailingAddress as MailingAddressState,
  Inputs as mailingAddressInputs,
} from "./sections/mailingAddress";
import {
  MemberProfile as MemberProfileState,
  Inputs as memberProfileInputs,
} from "./sections/memberProfile";
import {
  ProgramConfig as ProgramConfigState,
  Inputs as programConfigInputs,
} from "./sections/programConfig";
import {
  ProgramDetails as ProgramDetailsState,
  Inputs as programDetailsInputs,
} from "./sections/programDetails";
import {
  AltEnrollment as AltEnrollmentState,
  Inputs as altMemberEnrollmentInputs,
} from "./components/AltMemberEnrollment";
import {
  SiteInformation as SiteInformationState,
  Inputs as siteInformationInputs,
} from "./sections/siteInformation";
import {
  OtherInformation as OtherInformationState,
  Inputs as otherInformationInputs,
} from "./sections/otherInformation";
import {
  Reminders as RemindersState,
  Inputs as remindersInputs,
} from "./sections/reminders";
import { Inputs as demoReminderInputs } from "./sections/demoReminders";
import { Inputs as activeIdentityTagInputs } from "./sections/activeIdentityTags";
import { styles } from "./styles";
import { isReminderIncentivized } from "utils/programSpecific";

const DEFAULT_CURRENCY_SYMBOL = "$";

const STYLE_INFO_CHANGE: React.CSSProperties = {
  listStyleType: "none",
  margin: 0,
  padding: 0,
};

export interface EnrollmentSubmission {
  memberProfile: MemberProfileState;
  programConfig: ProgramConfigState;
  mailingAddress: MailingAddressState;
  programDetails: ProgramDetailsState;
  reminders: RemindersState;
  demoReminders: RemindersState;
  siteInformation: SiteInformationState;
  altEnrollment?: AltEnrollmentState;
  otherInformation: OtherInformationState;
  activeIdentityTags?: Partial<IdentityTag>[];
}

export interface SubmitProgramCodeResponse {
  reminders: RemindersState;
  programDetails: ProgramDetailsState;
}

export type ProgramConfig = Partial<ProgramConfigState> & {
  isValid?: boolean;
  isAltMember?: boolean;
  programCodeOptions: string[];

  submit: (programCode: string, timezone: string) => void;
};

export interface EnrollmentFormProps {
  memberProfile?: Partial<MemberProfileState>;
  programTemplate: string;
  programConfig: ProgramConfig;
  programDetails?: ProgramDetailsState;
  mailingAddress?: Partial<MailingAddressState>;
  reminders?: RemindersState;
  demoReminders?: RemindersState;
  siteInformation?: Partial<SiteInformationState>;
  otherInformation?: Partial<OtherInformationState>;
  submissionLoading: boolean;
  activeIdentityTags?: Partial<IdentityTag>[];

  validateMailingAddress: (address: MailingAddressState) => void;
  submit: (values: EnrollmentSubmission) => Promise<void>;
  prospectPhones: string[];
}

export interface FormState {
  memberProfile: Partial<MemberProfileState>;
  programConfig: Partial<ProgramConfigState>;
  programDetails: Partial<ProgramDetailsState>;
  reminders: Partial<RemindersState>;
  demoReminders: Partial<RemindersState>;
  siteInformation: Partial<SiteInformationState>;
  otherInformation: Partial<OtherInformationState>;
  mailingAddress: Partial<MailingAddressState>;
  activeIdentityTags: Partial<IdentityTag>[];

  isProgramCodeValid: boolean;
  hasValidatedAddress: boolean;
  enrollAltMember: boolean;
}

const configureReminderWindows = (
  category: CheckInCategory,
): { windowAfterMinutes: number; windowBeforeMinutes: number } => {
  switch (category) {
    case CheckInCategory.Meal:
      return { windowAfterMinutes: 360, windowBeforeMinutes: 360 };
    default:
      return { windowAfterMinutes: 180, windowBeforeMinutes: 360 };
  }
};

export const EnrollmentForm: React.FC<EnrollmentFormProps> = ({
  memberProfile: initialMemberProfile = {},
  programTemplate,
  programConfig: {
    submit: submitProgramCode,
    isValid: initialProgramCodeIsValid = false,
    isAltMember: initialEnrollAltMember = false,
    programCodeOptions,
    ...initialProgramCode
  },
  mailingAddress: initialMailingAddress,
  programDetails: initialProgramDetails,
  reminders: initialReminders,
  siteInformation: initialSiteInformation,
  otherInformation: initialOtherInformation,
  validateMailingAddress,
  submissionLoading,
  submit,
  activeIdentityTags: initalActiveIdentityTags,
  prospectPhones,
}) => {
  const memberProfileForm = useRef(null);
  const programConfigForm = useRef(null);
  const mailingAddressForm = useRef(null);
  const programDetailsTable = useRef(null);
  const remindersTable = useRef(null);
  const demoRemindersTable = useRef(null);
  const siteInformationForm = useRef(null);
  const otherInformationForm = useRef(null);
  const activeIdentityTagTable = useRef(null);

  const [formState, setFormState] = useState<FormState>({
    memberProfile: initialMemberProfile,
    programConfig: initialProgramCode,
    programDetails: initialProgramDetails,
    reminders: initialReminders.filter((r) => !r.isDemo),
    demoReminders: initialReminders.filter((r) => r.isDemo),
    isProgramCodeValid: initialProgramCodeIsValid,
    enrollAltMember: initialEnrollAltMember,
    siteInformation: initialSiteInformation,
    otherInformation: initialOtherInformation,
    mailingAddress: initialMailingAddress,
    hasValidatedAddress: false,
    activeIdentityTags: initalActiveIdentityTags,
  });
  const [isAltProgram, setIsAltProgram] = useState(false);
  const location = useLocation();
  const isBlankEnroll = location.pathname === "/enroll";

  const {
    memberProfile,
    programConfig,
    programDetails,
    reminders,
    demoReminders,
    isProgramCodeValid,
    siteInformation,
    otherInformation,
    mailingAddress,
    activeIdentityTags,
  } = formState;

  const AltProgramContainer: React.FC<SectionContainerProps> = ({
    children,
    title,
  }) => (
    <div>
      {title}
      {children}
      {isAltProgram && (
        <div style={styles.altProgramAlert}>
          <div>
            <p style={styles.altProgramAlertP}>
              <Icon
                type="exclamation-circle-o"
                style={styles.altProgramAlertIcon}
              />
              <span style={styles.altProgramAlertSpanBold}>
                Are you sure you want to enroll into the alt program?{" "}
              </span>
              <span style={styles.altProgramAlertSpan}>
                The alt program is a{" "}
                <span style={styles.altProgramAlertBold}>
                  paper based program
                </span>{" "}
                that does not include the Wellth Rewards app.
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );

  // Get the current state of the form or table via it's ref
  const getCurrentFormState = useCallback(
    () => {
      const currentFormState: Partial<FormState> = {};

      if (memberProfileForm.current) {
        currentFormState.memberProfile = memberProfileForm.current.props.form.getFieldsValue();
      }

      if (mailingAddressForm.current) {
        currentFormState.mailingAddress = mailingAddressForm.current.props.form.getFieldsValue();
      }

      if (programConfigForm.current) {
        currentFormState.programConfig = programConfigForm.current.props.form.getFieldsValue();
      }

      if (siteInformationForm.current) {
        currentFormState.siteInformation = siteInformationForm.current.props.form.getFieldsValue();
      }

      if (otherInformationForm.current) {
        currentFormState.otherInformation = otherInformationForm.current.props.form.getFieldsValue();
      }

      if (remindersTable.current) {
        currentFormState.reminders = remindersTable.current.props.dataSource;
      }

      if (demoRemindersTable.current) {
        currentFormState.demoReminders =
          demoRemindersTable.current.props.dataSource;
      }

      if (programDetailsTable.current) {
        currentFormState.programDetails =
          programDetailsTable.current.props.dataSource;
      }

      if (activeIdentityTagTable.current) {
        currentFormState.activeIdentityTags =
          activeIdentityTagTable.current.props.dataSource;
      }

      return currentFormState;
    },
    [
      memberProfileForm,
      mailingAddressForm,
      programConfigForm,
      siteInformationForm,
      otherInformationForm,
      remindersTable,
      demoRemindersTable,
      programDetailsTable,
      activeIdentityTagTable,
    ],
  );

  const validateProgramCode = async (code, timezone): Promise<void> => {
    await new Promise((resolve, reject) => {
      programConfigForm.current.props.form.validateFields((errors, values) =>
        errors ? reject(errors) : resolve(values),
      );
    });

    const isAlt = checkIfAltProgram(code);
    setFormState((previousFormState) => ({
      ...previousFormState,
      programConfig: {
        programCode: code,
        timezone,
      },
      isProgramCodeValid: true,
      enrollAltMember: isAlt,
    }));

    submitProgramCode(code, timezone);
    setIsAltProgram(isAlt);
  };

  const handleAddressValidation = useCallback(
    async (): Promise<void> => {
      let resolved = false;
      try {
        const addressComponents = await new Promise<MailingAddressState>(
          (resolve, reject) => {
            mailingAddressForm.current.props.form.validateFields(
              (errors, values) =>
                errors
                  ? reject(errors)
                  : resolve(values as MailingAddressState),
            );
          },
        );

        resolved = true;

        setFormState((previousFormState) => ({
          ...previousFormState,
          ...getCurrentFormState(),
          mailingAddress: addressComponents,
          hasValidatedAddress: true,
        }));

        validateMailingAddress(addressComponents);
      } catch (validationError) {
        // do nothing
      } finally {
        if (!resolved) {
          setFormState((previousFormState) => ({
            ...previousFormState,
            ...getCurrentFormState(),
            hasValidatedAddress: true,
          }));

          // !!!: Hack to ensure that the form shows the highlighted/error fields
          mailingAddressForm.current.props.form.validateFields();
        }
      }
    },
    [getCurrentFormState, validateMailingAddress],
  );
  // If the provided reminders, program details, or mailing
  // address change, update the form state.

  useEffect(
    () => {
      setFormState((previousFormState) => ({
        ...previousFormState,
        ...getCurrentFormState(),
        reminders: initialReminders.filter((r) => !r.isDemo),
        demoReminders: initialReminders.filter((r) => r.isDemo),
        programDetails: initialProgramDetails,
        activeIdentityTags: initalActiveIdentityTags,
      }));
    },
    [
      initialReminders,
      initialProgramDetails,
      initalActiveIdentityTags,
      getCurrentFormState,
    ],
  );

  useEffect(
    () => {
      setFormState((previousFormState) => ({
        ...previousFormState,
        ...getCurrentFormState(),
        mailingAddress: initialMailingAddress,
      }));
    },
    [getCurrentFormState, initialMailingAddress],
  );

  const { confirm } = Modal;
  function showConfirmDOBChangeModal(onOk) {
    confirm({
      title: "Confirm DOB Change",
      content:
        "The date of birth does not match our records for this Prospect. Are you sure you want to enroll as a Member?",
      onOk,
      okType: "danger",
    });
  }
  function showConfirmNameChangeModal(onOk, change: string[] = []) {
    const content = (
      <ul style={STYLE_INFO_CHANGE}>
        {change.map((i) => (
          <li key={i}>{i}</li>
        ))}
      </ul>
    );
    confirm({
      title: "Confirm Information Change",
      content,
      onOk,
      onCancel() {},
      okType: "danger",
    });
  }

  const validationAlertList: ValidationAlertContent = getValidationAlertList(
    programTemplate,
    formState,
  );

  return (
    <SectionedForm
      container={BoxLayout}
      sections={[
        {
          ...programConfigInputs({
            ref: programConfigForm,
            extraProps: programConfig,
            isProgramCodeValid,
            programCodeOptions,
            submitProgramCode: validateProgramCode,
          }),
          container: AltProgramContainer,
        },
        siteInformationInputs({
          ref: siteInformationForm,
          extraProps: siteInformation,
          hidden: !isProgramCodeValid,
        }),
        memberProfileInputs({
          ref: memberProfileForm,
          extraProps: memberProfile,
          hidden: !isProgramCodeValid,
          onValuesChange: ({ timezone }, allValues) => {
            if (timezone) {
              setFormState((previousFormState) => ({
                ...previousFormState,
                programConfig: programConfigForm.current.props.form.getFieldsValue(),
                memberProfile: { ...allValues },
              }));
            }
          },
        }),
        mailingAddressInputs({
          ref: mailingAddressForm,
          extraProps: mailingAddress,
          hidden: !isProgramCodeValid,
          validateAddress: handleAddressValidation,
        }),
        isAltProgram
          ? altMemberEnrollmentInputs({
              extraProps: {
                title: "Program Details",
                text:
                  "Alt programs do not have set Reward Period Payout Dates.",
                key: "altAlertDetails",
              },
            })
          : programDetailsInputs({
              ref: programDetailsTable,
              rewardCurrencySymbol: DEFAULT_CURRENCY_SYMBOL,
              dataSource: programDetails,
              hidden: !isProgramCodeValid,
            }),
        activeIdentityTagInputs({
          ref: activeIdentityTagTable,
          dataSource: activeIdentityTags,
          hidden: !isProgramCodeValid || activeIdentityTags.length <= 0,
        }),
        isAltProgram
          ? altMemberEnrollmentInputs({
              extraProps: {
                title: "Demo",
                text: "Alt programs do not have Demo Tasks.",
                key: "altAlertDemo",
              },
            })
          : demoReminderInputs({
              ref: demoRemindersTable,
              dataSource: demoReminders,
              hidden: !isProgramCodeValid,
              saveReminder: (reminder, index) => {
                const newReminders = [...demoReminders];
                newReminders.splice(index, 1, { ...reminder });

                setFormState((previousFormState) => ({
                  ...previousFormState,
                  ...getCurrentFormState(),
                  demoReminders: newReminders,
                }));
              },
              programCode: programConfig.programCode,
              programTemplate,
              externalId: siteInformation.customerId,
            }),
        isAltProgram
          ? altMemberEnrollmentInputs({
              extraProps: {
                title: "Reminders",
                text: "Alt programs do not have Reminders.",
                key: "altAlertReminders",
              },
            })
          : remindersInputs({
              ref: remindersTable,
              dataSource: reminders,
              hidden: !isProgramCodeValid,
              addReminder: () => {
                setFormState((previousFormState) => ({
                  ...previousFormState,
                  ...getCurrentFormState(),
                  reminders: [
                    ...remindersTable.current.props.dataSource,
                    {
                      checkInCategory: "MEDICATION",
                      interval: "DAY",
                      isDemo: false,
                      dayOfWeek: 0,
                      dueTime: moment("08:00:00", "HH:mm:ss"),
                      startDate: moment().add(1, "day"),
                      endDate: null,
                      isRequired: !isReminderIncentivized(
                        programTemplate,
                        CheckInCategory.Medication,
                      ),
                      windowAfterMinutes: 180,
                      windowBeforeMinutes: 360,
                    },
                  ],
                }));
              },
              saveReminder: (reminder, index) => {
                const newReminders = [...reminders];
                const {
                  windowAfterMinutes,
                  windowBeforeMinutes,
                } = configureReminderWindows(reminder.checkInCategory);
                newReminders.splice(index, 1, {
                  ...reminder,
                  windowAfterMinutes,
                  windowBeforeMinutes,
                });

                setFormState((previousFormState) => ({
                  ...previousFormState,
                  ...getCurrentFormState(),
                  reminders: newReminders,
                }));
              },
              deleteReminder: (reminder) => {
                const newReminders = [...reminders];
                const reminderIndex = newReminders.indexOf(reminder);
                newReminders.splice(reminderIndex, 1);

                // Update the form state with the latest from the form refs for
                // data consistency, and update with the latest reminders.
                setFormState((previousFormState) => ({
                  ...previousFormState,
                  ...getCurrentFormState(),
                  reminders: newReminders,
                }));
              },
              programCode: programConfig.programCode,
              programTemplate,
              externalId: siteInformation.customerId,
            }),
        otherInformationInputs({
          ref: otherInformationForm,
          hidden: !isProgramCodeValid,
          extraProps: {
            ...otherInformation,
            paymentOptions: initialOtherInformation.paymentOptions,
          },
        }),
      ]}
      submitButton={(props) =>
        isProgramCodeValid ? (
          <SubmitButton
            disabled={submissionLoading}
            {...props}
            submit={async () => {
              // Persist the form state in case of error
              setFormState((previousFormState) => ({
                ...previousFormState,
                ...getCurrentFormState(),
              }));

              // Get all refs that are currently in the UI tree
              try {
                const {
                  validatedFormData,
                  changes,
                  confirmDOB,
                } = await getValidatedFormData({
                  propSections: props.sections,
                  programTemplate,
                  initialMemberProfile,
                  isBlankEnroll,
                  isAltProgram,
                  prospectPhones,
                });

                if (confirmDOB) {
                  return showConfirmDOBChangeModal(() =>
                    submit(validatedFormData),
                  );
                }

                if (changes.length) {
                  return showConfirmNameChangeModal(
                    () => submit(validatedFormData),
                    changes,
                  );
                }

                return submit(validatedFormData);
              } catch ({ message }) {
                // Show the alert for 10s
                return alert.error(message, 10);
              }
            }}
          />
        ) : null
      }
    >
      {isAltProgram && (
        <AltProgramContainer key="altProgContainBottom" title="" />
      )}
      {isProgramCodeValid &&
        validationAlertList.map((validationAlert, index) => (
          <Alert
            key={`validationalert${index}`}
            message={validationAlert.message}
            description={validationAlert.description}
            type="error"
            style={styles.validationAlert}
          />
        ))}
    </SectionedForm>
  );
};

export default EnrollmentForm;
