import React from "react";
import { Tag } from "antd";
import { ReminderConfigType, getReminderConfigs } from "./config";

const HEADER_STYLE: React.CSSProperties = {
  fontSize: "16px",
};

const NOTE_STYLE: React.CSSProperties = {
  fontSize: "12px",
  fontWeight: 200,
  fontStyle: "italic",
};

const REMINDER_LABEL_STYLE: React.CSSProperties = {
  fontSize: "15px",
};

interface ReminderConfigProps {
  programCode?: string;
  programTemplate?: string;
  reminderConfigs?: ReminderConfigType[];
}

const RequiredTag: React.FC = () => <Tag color="green">Required</Tag>;
const OptionalTag: React.FC = () => <Tag color="blue">Optional</Tag>;

export const ReminderConfig: React.FC<ReminderConfigProps> = ({
  programCode,
  programTemplate,
}) => {
  const reminderConfigs = getReminderConfigs({ programCode, programTemplate });
  return (
    <div>
      <div style={HEADER_STYLE}>
        {programCode} {programTemplate} Reminder Configuration
      </div>
      <br />
      {reminderConfigs.length > 0 ? (
        reminderConfigs.map((config) => {
          return (
            <>
              <div style={REMINDER_LABEL_STYLE}>
                1 {config.type}{" "}
                {config.required ? <RequiredTag /> : <OptionalTag />}
              </div>
              {config.notes.map((note) => (
                <div style={NOTE_STYLE}>{note}</div>
              ))}
              <br />
            </>
          );
        })
      ) : (
        <div style={NOTE_STYLE}>NO REMINDER CONFIGS</div>
      )}
    </div>
  );
};
export default ReminderConfig;
