import React, { useRef, useState } from "react";
import TitledBoxLayout from "components/TitledBoxLayout";
import { IndividualType } from "types/globalTypes";
import Filters, {
  AdvancedSearchFormValues,
  AllProgramOptionType,
} from "./components/Filters";
import Table from "./components/Table";

const WARNING_TEXT_STYLE: React.CSSProperties = {
  fontSize: "15px",
  fontStyle: "italic",
  padding: "5px",
};

type SearchResult = {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  programCode: string;
  status: string;
  shortId: string;
  externalId: string;
  individualType: IndividualType;
  phoneNumber: string;
};

export interface AdvancedSearchProps {
  search: (values: AdvancedSearchFormValues) => void;
  loading: boolean;
  results: SearchResult[] | [];
  routeSearchResult: (
    result: { contactType: IndividualType; id: string },
  ) => void;
  showWarningMessage: boolean;
  programCodeOptions: AllProgramOptionType;
  programCodesLoading: boolean;
}

export const AdvancedSearch: React.FC<AdvancedSearchProps> = ({
  search,
  loading,
  programCodesLoading,
  programCodeOptions,
  results,
  routeSearchResult,
  showWarningMessage,
}) => {
  const filterRef = useRef(null);
  const [searchValues, setSearchValues] = useState<AdvancedSearchFormValues>({
    firstName: undefined,
    lastName: undefined,
    birthDate: undefined,
    shortId: undefined,
    externalId: undefined,
    phoneNumber: undefined,
  });

  return (
    <TitledBoxLayout title="Advanced Search" secondaryTitle="">
      <Filters
        ref={filterRef}
        searchValues={searchValues}
        programCodesLoading={programCodesLoading}
        programCodes={programCodeOptions}
        submit={(values: AdvancedSearchFormValues) => {
          setSearchValues(values);
          search(values);
        }}
      />
      {showWarningMessage ? (
        <div style={WARNING_TEXT_STYLE}>
          Search was unable to find a perfect match
        </div>
      ) : null}
      <Table
        loading={loading}
        rows={results}
        onClick={(record) => {
          routeSearchResult({
            contactType: record.individualType,
            id: record.id,
          });
        }}
      />
    </TitledBoxLayout>
  );
};

export default AdvancedSearch;
