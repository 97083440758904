import React from "react";
import {
  nonRelevantConditionText,
  useConditionsByExternalIdProgramCode,
} from "hooks/useConditions";

const CONTAINER_STYLE: React.CSSProperties = {
  border: "solid",
  borderRadius: "5px",
  borderWidth: "1px",
  borderColor: "#DDDDDD",
  padding: "10px",
};

const HEADER_STYLE: React.CSSProperties = {
  fontSize: "16px",
};

const CONDITION_STYLE: React.CSSProperties = {
  fontSize: "12px",
  fontWeight: 200,
};

const createConditionsText = (conditions: string[]) => {
  const conditionTextMap: { [key: string]: string } = {
    "Diabetes (type 2)": "has Diabetes (Type 2) · recommend Glucose",
    Hypertension: "has Hypertension · recommend Blood Pressure",
  };

  const conditionItems = conditions
    .map((condition) => conditionTextMap[condition])
    .filter(Boolean);

  // If there are no authorized conditions, return the fallback text
  return conditionItems.length > 0
    ? conditionItems.map((item, index) => <div key={index}>{item}</div>)
    : nonRelevantConditionText;
};

interface ConditionsProps {
  externalId: string;
  programCode: string;
}

export const Conditions: React.FC<ConditionsProps> = ({
  externalId,
  programCode,
}) => {
  const { conditionNames, error } = useConditionsByExternalIdProgramCode(
    externalId,
    programCode,
  );

  return (
    <div style={CONTAINER_STYLE}>
      {error ? (
        <div>{`Error fetching conditions: ${error}`}</div>
      ) : (
        <div>
          <div style={HEADER_STYLE}>Prospect Conditions</div>
          <div style={CONDITION_STYLE}>
            {createConditionsText([...conditionNames])}
          </div>
        </div>
      )}
    </div>
  );
};

export default Conditions;
