import React from "react";
import moment from "moment-timezone";
import styles from "../../styles";

const MESSAGE_FORMAT = "ddd, MMM Do YYYY h:mm A";
const ADMIN_RECIPIENT = "ADMIN";

export type Recipient = "ADMIN" | "PATIENT";

export interface Props {
  createdAt: Date;
  messageBody: string;
  automated?: boolean;
  recipient: Recipient;
}

const MessageList: React.FC<Props> = ({
  createdAt,
  messageBody,
  recipient,
  automated,
}: Props) => {
  let messageStyle;
  if (recipient === ADMIN_RECIPIENT) {
    messageStyle = { ...styles.patient, ...styles.messageBubble };
  } else {
    messageStyle = automated
      ? styles.auto
      : { ...styles.messageBubble, ...styles.admin };
  }

  return (
    <div style={styles.bubbleContainer}>
      <h5 style={styles.sentDate}>
        {moment(createdAt).format(MESSAGE_FORMAT)}
      </h5>
      <div style={messageStyle}>{messageBody}</div>
    </div>
  );
};

export default MessageList;
