import { Moment } from "moment";
import { dayIsInThePast } from "utils/date";
import { AdminPrivilege } from "constants/adminPrivilege";
import { FormInputProps, FormInputType } from "@wellth/web-ui";
import { CheckInCategory, ReminderInterval } from "types/globalTypes";
import { isSuperAdmin } from "utils/roles";

const INPUT_STYLE: React.CSSProperties = {
  height: "2.1875rem",
  width: "18.75rem",
};

const WARNING_STYLE: React.CSSProperties = {
  color: "red",
  width: "28.75rem",
  height: "auto",
  lineHeight: "normal",
  paddingLeft: "5rem",
};

export enum ReminderMethod {
  Create = "create",
  Info = "info",
  Edit = "edit",
}

export interface FormProps {
  id?: string;
  checkInCategory: CheckInCategory;
  interval: ReminderInterval;
  startDate: Moment;
  endDate: Moment;
  dueTime: Moment;
  windowAfterMinutes: string;
  windowBeforeMinutes: string;
  isRequired: boolean;
}

const shouldPreventPastDates = (current: Date, timezone: string) =>
  current && dayIsInThePast(current, timezone);

enum InputKey {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  CheckInCategory = "checkInCategory",
  Interval = "interval",
  ReminderTime = "dueTime",
  StartDate = "startDate",
  EndDate = "endDate",
  WindowBeforeMinutes = "windowBeforeMinutes",
  WindowAfterMinutes = "windowAfterMinutes",
}

const titleForInputKey = (key: InputKey) => {
  switch (key) {
    case InputKey.CheckInCategory:
      return "Reminder type:";
    case InputKey.Interval:
      return "Frequency:";
    case InputKey.ReminderTime:
      return "Due time:";
    case InputKey.StartDate:
      return "Start date:";
    case InputKey.EndDate:
      return "End date:";
    case InputKey.WindowBeforeMinutes:
      return "Window before (minutes):";
    case InputKey.WindowAfterMinutes:
      return "Window after (minutes):";
    default:
      throw new Error("Invalid InputKey provided");
  }
};

const infoInputForInputKey = (key: InputKey): FormInputProps => ({
  key,
  type: FormInputType.Info,
  title: titleForInputKey(key),
  style: INPUT_STYLE,
});

export default (
  disableStartDatePicker: boolean,
  timezone: string,
  method: ReminderMethod,
  warning: boolean = false,
  adminRole: AdminPrivilege,
): FormInputProps[] => {
  const editing = method === ReminderMethod.Edit;
  const superAdmin = isSuperAdmin(adminRole);

  const windowBeforeMinutesInput: FormInputProps = superAdmin
    ? {
        type: FormInputType.Text,
        title: titleForInputKey(InputKey.WindowBeforeMinutes),
        key: InputKey.WindowBeforeMinutes,
        placeholder: "Input window before minutes",
        style: INPUT_STYLE,
        decoratorOptions: {
          rules: [
            {
              required: true,
              pattern: /^\d+$/,
              message:
                "Window before is required and needs to be a positive number",
            },
          ],
        },
      }
    : {
        type: FormInputType.Info,
        title: titleForInputKey(InputKey.WindowBeforeMinutes),
        key: InputKey.WindowBeforeMinutes,
        placeholder: "Input window before minutes",
        style: INPUT_STYLE,
        decoratorOptions: {
          rules: [
            {
              required: true,
              pattern: /^\d+$/,
              message:
                "Window before is required and needs to be a positive number",
            },
          ],
        },
      };

  const windowAfterMinutesInput: FormInputProps = superAdmin
    ? {
        type: FormInputType.Text,
        title: titleForInputKey(InputKey.WindowAfterMinutes),
        key: InputKey.WindowAfterMinutes,
        placeholder: "Input window after minutes",
        style: INPUT_STYLE,
        decoratorOptions: {
          rules: [
            {
              required: true,
              pattern: /^\d+$/,
              message:
                "Window After is required and needs to be a positive number",
            },
          ],
        },
      }
    : {
        type: FormInputType.Info,
        title: titleForInputKey(InputKey.WindowAfterMinutes),
        key: InputKey.WindowAfterMinutes,
        placeholder: "Input window after minutes",
        style: INPUT_STYLE,
        decoratorOptions: {
          rules: [
            {
              required: true,
              pattern: /^\d+$/,
              message:
                "Window After is required and needs to be a positive number",
            },
          ],
        },
      };

  return method === ReminderMethod.Info
    ? [
        infoInputForInputKey(InputKey.CheckInCategory),
        infoInputForInputKey(InputKey.Interval),
        infoInputForInputKey(InputKey.ReminderTime),
        infoInputForInputKey(InputKey.StartDate),
        infoInputForInputKey(InputKey.EndDate),
        infoInputForInputKey(InputKey.WindowBeforeMinutes),
        infoInputForInputKey(InputKey.WindowAfterMinutes),
      ]
    : [
        {
          key: "warningText",
          type: FormInputType.Info,
          title: "",
          style: WARNING_STYLE,
          hidden: !warning,
        },
        {
          type: FormInputType.Dropdown,
          title: titleForInputKey(InputKey.CheckInCategory),
          key: InputKey.CheckInCategory,
          placeholder: "Select a reminder type",
          style: INPUT_STYLE,
          disabled: !!editing,
          decoratorOptions: { rules: [{ required: true }] },
          options: [
            { title: "Medication", value: CheckInCategory.Medication },
            { title: "Glucose", value: CheckInCategory.Glucose },
            { title: "Meal", value: CheckInCategory.Meal },
            { title: "Blood Pressure", value: CheckInCategory.BloodPressure },
            {
              title: "Sleep (connected)",
              value: CheckInCategory.SleepConnected,
            },
          ],
        },
        {
          type: FormInputType.Dropdown,
          title: titleForInputKey(InputKey.Interval),
          key: InputKey.Interval,
          placeholder: "Select a frequency",
          style: INPUT_STYLE,
          disabled: !!editing,
          decoratorOptions: { rules: [{ required: true }] },
          options: [
            { title: "Daily", value: ReminderInterval.Day },
            { title: "Weekly", value: ReminderInterval.Week },
          ],
        },
        {
          type: FormInputType.Time,
          title: titleForInputKey(InputKey.ReminderTime),
          key: InputKey.ReminderTime,
          style: INPUT_STYLE,
          decoratorOptions: {
            rules: [{ required: true, message: "Reminder time is required" }],
          },
        },
        {
          type: FormInputType.Date,
          format: "LL",
          title: titleForInputKey(InputKey.StartDate),
          key: InputKey.StartDate,
          style: INPUT_STYLE,
          disabled: disableStartDatePicker,
          decoratorOptions: {
            rules: [{ required: true, message: "Start Date is required" }],
          },
          disabledDate: (current) =>
            shouldPreventPastDates(current.toDate(), timezone),
        },
        {
          type: FormInputType.Date,
          title: titleForInputKey(InputKey.EndDate),
          format: "LL",
          key: InputKey.EndDate,
          style: INPUT_STYLE,
          decoratorOptions: {
            rules: [{ required: true, message: "End Date is required" }],
          },
          disabledDate: (current) =>
            shouldPreventPastDates(current.toDate(), timezone),
        },
        windowBeforeMinutesInput,
        windowAfterMinutesInput,
      ];
};
